import { Component } from "react";

class EXLogo extends Component {
  render() {
    return (
      <div className="text-center">
        <a href="/">
          <img
            src="/images/logo.png"
            alt="exurl logo"
            width="300"
          />
        </a>
      </div>
    );
  }
}

export default EXLogo;
