import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useState, useEffect } from "react";
import axios from "axios";
import { useCookies, CookiesProvider } from "react-cookie";
import NotificationAlert from "./Notifications";
import UrlCard from "./UrlCard";
import { config } from "../constants";
import { isEmpty, safeCopyToClipboard } from "../helpers";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons";

function UrlForm() {
  const [formData, setFormData] = useState({
    longUrl: "",
    alias: "",
  });
  const [expiresAt, setExpiresAt] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationContent, setNotificationContent] = useState({
    status: "",
    message: "",
    type: "success"
  });
  const [urls, setUrls] = useState([]);
  const [cookies, setCookie] = useCookies(["urls"]);

  useEffect(() => {
    if (cookies.urls) {
      setUrls(cookies.urls);
    }
  }, [cookies]);

  const handleLongUrlAndAliasChange = (event) => {
    // This is a really subtle bug.
    // The issue is that once we've set the custom validity error message, it
    // needs to be reset back to an empty string.
    // The best place to do that is in the change handler.
    // See: https://stackoverflow.com/questions/59763647/oninvalid-setcustomvalidity-field-will-not-validate-after-showing-custom-error-m
    event.target.setCustomValidity("");
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleShortenError = (e) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setNotificationContent({
      status: "Ooops! Something went wrong...",
      message: e?.error,
      type: "alert"
    });
    setShowNotification(true);
  };

  const handleDateChange = (event) => {
    const date = new Date(event.target.value);
    // Do not set expiresAt to an invalid date
    if (!isNaN(date.getTime())) {
      setExpiresAt(date);
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    let submitParams;
    if (expiresAt) {
      submitParams = Object.assign({}, formData, { expiresAt: expiresAt.toISOString().substring(0, 10) });
    } else {
      submitParams = formData;
    }
    axios
      .post(config.url.SHORTEN_API_URL, submitParams)
      .then((response) => {
        var shortUrlResponse = response.data.shortUrl;
        if (!isEmpty(shortUrlResponse)) {
          const updatedUrls = [...urls, { longUrl: formData.longUrl, shortUrl: shortUrlResponse }];
          setUrls(updatedUrls);
          setCookie("urls", updatedUrls, { path: "/" });
          var urlToCopy = `${config.url.BASE_APP_URL}/${shortUrlResponse}`;
          safeCopyToClipboard(urlToCopy);
          window.scrollTo({ top: 0, behavior: "smooth" });
          setNotificationContent({
            status: "Success!",
            message: `Short url ${urlToCopy} copied to your clipboard.`,
            type: "primary"
          });
          setShowNotification(true);
        } else {
          handleShortenError(response.data);
        }
      })
      .catch((error) => {
        setNotificationContent({
          status: "Ooops! Something went wrong...",
          message: error?.message,
          type: "alert"
        });
        setShowNotification(true);
      });
  };

  return (
    <CookiesProvider>
      <div className="shorten-card">
        <div className="row">
          <h1 className="text-overlay text-white">Botswana&#39;s best URL Shortener</h1>
          <p className="text-overlay color-primary">
            Our URL shortening service helps you create clean, short links that
            are easy to share and track.
          </p>
        </div>
        <div className="row">
          <NotificationAlert
            show={showNotification}
            delay={5000}
            status={notificationContent.status}
            message={notificationContent.message}
            type={notificationContent.type}
            handleToastClose={() => setShowNotification(false)}
          />
        </div>
        <div className="row">
          <Form onSubmit={submitForm} className="form-horizontal">
            <InputGroup>
              <Form.Control
                type="text"
                placeholder="Enter a long url to shorten"
                name="longUrl"
                value={formData.longUrl}
                className="form-control"
                size="lg"
                aria-label="Long url to shorten"
                onChange={handleLongUrlAndAliasChange}
                pattern="([Hh][Tt][Tt][Pp][Ss]?:\/\/)?(?:(?:[a-zA-Z0-9]+-?)*[a-zA-Z0-9]+)(?:\.(?:[a-zA-Z0-9]+-?)*[a-zA-Z0-9]+)*(?:\.(?:[a-zA-Z]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?"
                onInvalid={(event) =>
                  event.target.setCustomValidity("Please enter a valid url.")
                }
                required
              />
            </InputGroup>
            <Form.Group>
              <div className='row'>
                <p className="mb-0 mt-2">
                  <FontAwesomeIcon icon={faWandMagicSparkles} className="fas" />
                  <span>Customize your link with an optional alias or expiration date</span>
                </p>
              </div>
              <InputGroup className="py-2">
                <Form.Control
                  type='text'
                  placeholder="Custom alias"
                  name="alias"
                  className="form-control"
                  size="lg"
                  aria-label="Long url alias"
                  pattern="[a-zA-Z0-9_\-]{1,255}"
                  onChange={handleLongUrlAndAliasChange}
                  onInvalid={(event) => {
                    const value = event.target.value;
                    if (value.length > 255) {
                      event.target.setCustomValidity("Alias must be less than 255 characters.");
                    } else {
                      event.target.setCustomValidity("Alias can only contain hypens, underscores, and alphanumeric characters.");
                    };
                  }
                  }
                />
                <Form.Control
                  type="date"
                  name="expires_at"
                  className="form-control"
                  size="lg"
                  aria-label="Expiry date for the short url"
                  min="today"
                  max="2027-07-31" // Dates must be in ISO format
                  onChange={handleDateChange}
                  defaultValue={null}
                />
              </InputGroup>
            </Form.Group>
            <InputGroup>
              <div className="d-grid col-12">
                <Button
                  variant="primary"
                  type="submit"
                  size="lg"
                >
                  Shorten URL
                </Button>
              </div>
            </InputGroup>
          </Form>
        </div>
        <div className="row mt-3">
          {urls.toReversed().map((url) => (
            <UrlCard
              key={url.shortUrl}
              longUrl={url.longUrl}
              shortUrl={url.shortUrl}
              setNotificationContent={setNotificationContent}
              setShowNotification={setShowNotification}
            />
          ))}
        </div>
      </div>
    </CookiesProvider>
  );
}

export default UrlForm;
