import axios from "axios";
import { config } from "../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faArrowUpRightFromSquare,
  faQrcode,
  faChartColumn
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { PropTypes } from "prop-types";
import QRModal from "./QRModal";
import { safeCopyToClipboard } from "../helpers";

UrlCard.propTypes = {
  longUrl: PropTypes.string,
  shortUrl: PropTypes.string,
  setNotificationContent: PropTypes.func,
  setShowNotification: PropTypes.func,
};

function UrlCard({ longUrl, shortUrl, setNotificationContent, setShowNotification }) {
  const [showModal, setShowModal] = useState(false);
  const [qrContent, setQrContent] = useState("");

  const handleCopyToClipboard = () => {
    var urlToCopy = `${config.url.BASE_APP_URL}/${shortUrl}`;
    window.scrollTo({ top: 0, behavior: "smooth" });
    safeCopyToClipboard(urlToCopy);
    setNotificationContent({
      status: "Success!",
      message: `Short url ${urlToCopy} copied to your clipboard`,
      type: "primary",
    });
    setShowNotification(true);
  };

  const handleQRCode = () => {
    setShowModal(true);
    axios
      .post(config.url.QRCODE_API_URL, { shortUrl: shortUrl })
      .then((response) => {
        let qrCodeResponse;
        if (process.env.NODE_ENV === "production") {
          qrCodeResponse = response.data.fileName;
        } else {
          qrCodeResponse = `${config.url.BASE_APP_URL}/qr_codes/${response.data.fileName}`;
        };
        setQrContent(qrCodeResponse);
      })
      .catch((error) => {
        setNotificationContent({
          status: "Ooops! Something went wrong...",
          message: error?.message,
        });
        setShowNotification(true);
      });
  };

  const handleStatClick = () => {
    window.open(`stats/${shortUrl}`, "_blank", "noopener, noreferrer");
  };

  return (
    <div>
      <div className="card mt-3 card-links-history">
        <div className="card-body d-flex justify-content-between align-items-center">
          <div className="card-text card-link-text">
            <div>
              <strong>
                <FontAwesomeIcon
                  icon={faArrowUpRightFromSquare}
                  className="fas"
                />
              </strong>
              <a href={shortUrl} target="_blank" rel="noopener noreferrer">
                <span>{shortUrl}</span>
              </a>
            </div>
            <div className="card-text d-none d-sm-block">
              <strong>
                <img
                  src={`https://www.google.com/s2/favicons?domain=${longUrl}`}
                  alt="favicon"
                />
              </strong>
              <span>{longUrl}</span>
            </div>
          </div>
          <button
            onClick={handleStatClick}
            className="btn btn-space"
            data-toggle="tooltip"
            data-placement="bottom"
            title="View stats"
          >
            <FontAwesomeIcon icon={faChartColumn} className="fas" />
          </button>
          <button
            onClick={handleQRCode}
            className="btn btn-space"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Generate QR Code"
          >
            <FontAwesomeIcon icon={faQrcode} className="fas" />
          </button>
          <button
            onClick={handleCopyToClipboard}
            className="btn"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Copy to Clipboard"
          >
            <FontAwesomeIcon icon={faCopy} className="fas" />
          </button>
        </div>
      </div>
      <QRModal
        showModal={showModal}
        setShowModal={setShowModal}
        shortUrl={shortUrl}
        qrContent={qrContent}
      />
    </div>
  );
}

export default UrlCard;
