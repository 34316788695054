import Container from "react-bootstrap/Container";
import EXLogo from "../components/EXLogo";
import Highlights from "../components/landing/Highlights";
import UrlForm from "../components/UrlForm";

function Home() {

  return (
    <Container>
      <header className="App-header">
        <EXLogo />
      </header>
      {<div >
        <div>
          <UrlForm />
        </div>
        <div className='mt-5'>
          <Highlights />
        </div>
      </div>}
    </Container>
  );
}

export default Home;
