import { Modal, Button } from "react-bootstrap";
import { PropTypes } from "prop-types";

import { config } from "../constants";

QRModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  shortUrl: PropTypes.string,
  qrContent: PropTypes.string
};

function QRModal({ showModal, setShowModal, shortUrl, qrContent }) {
  return (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header>
        <Modal.Title className='text-center'>
          <h4>{config.url.BASE_APP_URL}/qr/{shortUrl}</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='text-center'>
        { qrContent ?
          <img src={qrContent} alt={shortUrl} style={{ width: "100%" }}/>
          :
          <div>
            <img src="/images/logo_blue.png" alt='EXURL Logo' style={{ width: "120px" }}/>
            <h5 className='mt-2'>Loading....</h5>
          </div>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button className="background-secondary" onClick={() => setShowModal(false)}>
            Close
        </Button>
        <a href={qrContent} download={shortUrl}>
          <Button className="background-primary">
              Download
          </Button>
        </a>
      </Modal.Footer>
    </Modal>
  );
}

export default QRModal;
