const isEmpty = (obj) => {
  if (obj == null) {return true;} // Check for null or undefined
  if (Array.isArray(obj) || typeof obj === "string") {return obj.length === 0;} // Check for empty array or string
  if (typeof obj === "object") {return Object.keys(obj).length === 0;} // Check for empty object
  if (!obj || typeof obj === "undefined") {return true;} // Check for null or undefined
  return false; // Default case for other types
};

/**
 * Copies the text passed as param to the system clipboard
 * Check if using HTTPS and navigator.clipboard is available
 * Then uses standard clipboard API, otherwise uses fallback
*/
const safeCopyToClipboard = (content) => {
  if (window.isSecureContext && navigator.clipboard) {
    navigator.clipboard.writeText(content);
  } else if (process.env.NODE_ENV === "test") {
    navigator.clipboard.writeText(content);
  }
};

export { isEmpty, safeCopyToClipboard };
