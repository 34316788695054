import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import "./App.css";

import Home from "./pages/Home";
import NoMatch from "./pages/NoMatch";
import Stats from "./pages/Stats";
import { config, URL_REGEX } from "./constants";
import { isEmpty } from "./helpers";
import EXLogo from "./components/EXLogo";
import NotificationAlert from "./components/Notifications";

export default function App() {
  const [isRedirecting, setIsRedirecting] = useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationContent, setNotificationContent] = useState({
    status: "",
    message: "",
    type: "success"
  });

  const handleLengthenError = (error, redirectToHome = false) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setNotificationContent({
      status: "Ooops! Something went wrong...",
      message: error?.message,
      type: "alert"
    });
    setShowNotification(true);
    if (redirectToHome){
      setTimeout(() => {
        window.location.replace("/");
      }, 3000);
    }
  };

  useEffect(() => {
    var path = window.location.pathname;
    // eslint-disable-next-line no-useless-escape
    const regex = new RegExp("(/qr/)?([a-zA-Z0-9_\-]{1,255})");
    const matchString = regex.exec(path);
    setIsRedirecting(matchString);
    // Get and redirect to long URL if match is correct
    // If no match then render page
    if (matchString) {
      let process;
      let shortUrl;
      if (path.includes("/qr/")) {
        shortUrl = matchString[2];
        // QR_LOAD is 4
        process = "4";
      } else {
        shortUrl = matchString[0];
        // LENGTHEN is 2
        process = "2";
      }
      axios.post(config.url.LENGTHEN_API_URL, { "shortUrl": shortUrl, process: process })
        .then(response => {
          var longUrl = response.data.longUrl;
          const urlRegex = new RegExp(URL_REGEX);
          if (!isEmpty(longUrl) && urlRegex.exec(longUrl)) {
            // Many websites don't work without the protocol. So, if there is no
            // protocol, add one and hope that that works for the user. If not, tough luck.
            if (!longUrl.toLowerCase().includes("http")) {
              longUrl = "https://" + longUrl;
            }
            window.location.replace(longUrl);
          } else {
            setIsRedirecting(false);
            const shortenError = { message: "That short url does not exist or has expired" };
            handleLengthenError(shortenError);
          }
        })
        .catch(error => {
          setIsRedirecting(false);
          handleLengthenError(error);
        });
    }
  }, []);

  return (
    <>
      {isRedirecting && (
        <div>
          <div>
            <NotificationAlert
              show={showNotification}
              delay={5000}
              status={notificationContent.status}
              message={notificationContent.message}
              type={notificationContent.type}
              handleToastClose={() => setShowNotification(false)}
            />
          </div>
          <header className="App-header">
            <EXLogo />
          </header>
        </div>
      )}
      {!isRedirecting && (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="/stats/:shortUrl" element={<Stats />} />
              <Route path="/:shortUrl" element={<Home />} />
              <Route path="qr/:shortUrl" element={<Home />} />
              <Route path="*" element={<NoMatch />} />
            </Route>
          </Routes>
        </BrowserRouter>
      )}
    </>
  );
}

function Layout() {
  return (
    <div className="App">
      <Outlet />
    </div>
  );
}
