let BASE_APP_URL;
let BASE_API_URL;

if (process.env.NODE_ENV === "production") {
  BASE_APP_URL = "https://exurl.co";
  BASE_API_URL = BASE_APP_URL;
} else {
  BASE_APP_URL = "http://localhost:3439";
  BASE_API_URL = "http://localhost:8000";
};

// eslint-disable-next-line no-useless-escape
const URL_REGEX = "([Hh][Tt][Tt][Pp][Ss]?:/\/)?(?:(?:[a-zA-Z0-9]+-?)*[a-zA-Z0-9]+)(?:\.(?:[a-zA-Z0-9]+-?)*[a-zA-Z0-9]+)*(?:\.(?:[a-zA-Z]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?";

export const config = {
  url: {
    BASE_APP_URL: BASE_APP_URL,
    LENGTHEN_API_URL: `${BASE_API_URL}/api/lengthen`,
    SHORTEN_API_URL: `${BASE_API_URL}/api/shorten`,
    QRCODE_API_URL: `${BASE_API_URL}/api/qrcode`,
    STATS_API_URL: `${BASE_API_URL}/api/stats`,
  }
};

export { URL_REGEX };
