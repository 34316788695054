import Container from "react-bootstrap/Container";
import EXLogo from "../components/EXLogo";

function NoMatch() {
  return (
    <Container>
      <header className="App-header mb-5">
        <EXLogo />
      </header>
      <p>404, Page Not Found.</p> 
    </Container>
  );
}

export default NoMatch;
