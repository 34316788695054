import Row from "react-bootstrap/Row";
import { faChartColumn, faMinimize } from "@fortawesome/free-solid-svg-icons";
import { faChrome } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropTypes } from "prop-types";

const EXIcon = ({ icon, title, description }) => {
  return (
    <div className="col-lg-4">
      <FontAwesomeIcon icon={icon} className="fas fa-5x highlight-icon" />
      <h4>{title}</h4>
      <p>
        {description}
      </p>
    </div>
  );
};

EXIcon.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
};

function Highlights() {
  return (
    <Row>
      <EXIcon
        icon={faMinimize}
        title={"Shorten any link"}
        description={
          "This URL shortening service provided by EasyXchange helps you create shorter, clean, trackable links that you can share with friends and family."
        }
      />
      <EXIcon
        icon={faChartColumn}
        title={"Track Link clicks"}
        description={
          "Our service let's you track link analytics, all for free."
        }
      />
      <EXIcon
        icon={faChrome}
        title={"Browser Extension (coming soon)"}
        description={
          "We'll be releasing a chrome browser extension soon to help you shorten links anywhere you are on the internet."
        }
      />
    </Row>
  );
}

export default Highlights;
